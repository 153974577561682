import axios from '@axios'

export default {
  namespaced: true,
  state: {
    cartItemsCount: (() => {
      const userData = JSON.parse(localStorage.getItem('userData'))
      return userData && userData.extras ? userData.extras.eCommerceCartItemsCount : 0
    })(),
  },
  getters: {},
  mutations: {
    UPDATE_CART_ITEMS_COUNT(state, count) {
      state.cartItemsCount = count
    },
  },
  actions: {
    fetchOrder(ctx, {
      searchKeyWord, dateFrom, dateTo, payGateId, status, page,
    }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/from-merchant/payins', {
            params: {
              q: searchKeyWord ?? null,
              orderBy: 'created_at',
              sortedBy: 'desc',
              page,
              limit: 100,
              dateFrom,
              dateTo,
              payGateId,
              status,
            },
          })
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    fetchMerchantParentMaster(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get('/get-all-parent-merchants', { params: payload })
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    fetchPayGateMaster(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get('/pay-order/pay-gates', { params: payload })
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    fetchStatistic(ctx, { type }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/general-analyze', {
            params: {
              type,
            },
          })
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    fetchPayout(ctx, {
      searchKeyWord, dateFrom, dateTo, payGateId, status, page,
    }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/payouts', {
            params: {
              q: searchKeyWord ?? null,
              orderBy: 'created_at',
              sortedBy: 'desc',
              page,
              limit: 100,
              dateFrom,
              dateTo,
              payGateId,
              status,
            },
          })
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    fetchBalanceHistory(ctx, {
      page,
      search,
      date_from,
      date_to,
      trans_type,
      type,
      status,
    }) {
      let keyword = []
      if (search) {
        keyword = [
          `transaction_id:${search}`,
          `payoutDetail.receiver_number:${search}`,
          `payoutDetail.content:${search}`,
          `payoutDetail.account_number:${search}`,
          `payoutDetail.account_name:${search}`,
        ]
      }

      return new Promise((resolve, reject) => {
        axios
          .get('/pay-orders', {
            params: {
              page,
              limit: 100,
              date_from,
              date_to,
              trans_type,
              type,
              status,
              search: keyword.length > 0 ? keyword.join(';') : null,
            },
          })
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    createWithdrawal(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('/withdrawals', payload)
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    fetchWithdrawal(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get('/withdrawals', {
            params: payload,
          })
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },

    fetchBankAvailable(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get('/banks?search=status:available', { params: payload })
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    fetchWithdrawStatistic(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get('/statistic-withdraw', { params: payload })
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
  },
}
