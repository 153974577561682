export default [
  {
    path: '/account-manager',
    name: 'account-manager',
    component: () => import('@/views/account-manager/AccountManager.vue'),
  },

  // *===============================================---*
  // *--------- EMAIL & IT'S FILTERS N LABELS -------------------------------*
  // *===============================================---*
  {
    path: '/order-management/pay-in',
    name: 'order-management',
    component: () => import('@/views/order-management/Payin.vue'),
  },
  {
    path: '/order-management/pay-out',
    name: 'order-management',
    component: () => import('@/views/order-management/Payout.vue'),
  },
]
