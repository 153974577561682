import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    createBankAccount(ctx, bankAccount) {
      return new Promise((resolve, reject) => {
        axios
          .post('/banks', bankAccount)
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    updateBankAccount(ctx, bankAccount) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/banks/${bankAccount.id}`, bankAccount)
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    getBankMasterData() {
      return new Promise((resolve, reject) => {
        axios
          .get('/banks-masters')
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    getBankAccounts(ctx, { search, page }) {
      let searchStr = ''
      if (search) {
        searchStr += `bank_number:${search};bank_account:${search};nickname:${search};`
      }
      return new Promise((resolve, reject) => {
        axios
          .get('/banks', {
            params: {
              search: searchStr !== '' ? searchStr : null,
              page,
            },
          })
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    deleteBankAccount(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/banks/${id}`)
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
  },
}
