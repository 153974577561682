export default [
  {
    path: '/transaction-history',
    name: 'transaction-history',
    component: () => import('@/views/order-management/BalanceHistory.vue'),
  },
  {
    path: '/usdt-management',
    name: 'usdt-management',
    component: () => import('@/views/order-management/UsdtWallet.vue'),
  },
  {
    path: '/usdt-withdraw-history',
    name: 'usdt-withdraw-history',
    component: () => import('@/views/order-management/UsdtWithdraw.vue'),
  },
]
